<template>
  <div>
    <headTop :currentrStr="currentrStr" sfType="学生"></headTop>
    <div class="common-width con-box">
      <el-breadcrumb separator="/" style="margin-bottom:15px;">
        <el-breadcrumb-item :to="typeTo =='student'? { path: '/list' }:{ path: '/pre' }">返回上一页</el-breadcrumb-item>
        <el-breadcrumb-item>scratch  </el-breadcrumb-item>
     
      </el-breadcrumb>
      <ul class="">
        <li
          @click="to(item.type, item.number)"
          v-for="item in arryData"
          :key="item"
        >
          <img :src="item.img" class="image" width="100%" />

          <p>{{ item.title }}</p>
          <span class="go-learn">去学习</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import headTop from "../components/header.vue";
export default {
  components: {
    headTop,
  },
  data() {
    return {
        typeTo:window.localStorage.getItem('anjing-token-group'),
      currentrStr: ["index"],
      arryData: [
        {
          title: "Scratch编程-入门",
          detail: "",
          img: "https://bth.educg.net/images/addImages/scratch-rumen.png",
          type: "Scratch编程入门",
          number: "0",
        },
        {
          title: "Scratch编程-基础",
          detail: "",
          img: "	https://bth.educg.net/images/addImages/scratch_bg_02.png",
          type: "Scratch编程基础",
          number: "33",
        },
        {
          title: "Scratch编程-计算思维",
          detail: "",
          img: "	https://bth.educg.net/images/addImages/scratch-js.png",
          type: "Scratch编程计算思维",
          number: "53",
        },
      ],
    };
  },
  methods: {
    to(type, pathNum) {
      // onclick="targetUrl('/userInfo/scratch.html?type=Scratch编程入门&pathNum=0')"
      this.$router.push({
        path: "list-scratch",
        query: { type: type, pathNum: pathNum },
      });
    },
   
  },
};
</script>
<style scoped>
ul,
li,
div,
p,
span {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-around;
}
ul li {
  margin-bottom: 10px;
  cursor: pointer;

  background: #fff;

  border: 1px solid #ccc;
}
.go-learn {
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #1890ff;
}
.con-box {
  margin-top: 30px !important;
}
</style>